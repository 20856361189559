.tools{
	.tool{
		float: left;
		width: 33%;
		background-color: white;
		border: none;
		border-radius: 10px;
		position: relative;
		.tool-inner{
			padding: 15px;
			h2{
				margin-top: 5px;
			}
			button{
				width: 100%;
			}
		}
	}
}