.Language-icon {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Language-icon:hover {
  color: #C2C2C2;
}

.Language-dropdown {
  position: absolute;
  top: 70px;
  right: 0;
  background-color: white;
  min-width: 150px;
  height: 0;
  overflow-y: hidden;
  transition: height 0.5s linear;
  font-size: 18px;
}

.Language-dropdown-open {
  height: 77px;
}

.Language-dropdown-noborder {
  border: none;
}

.Language-dropdown-item {
  padding: 8px 20px;
  border-bottom: 1px solid #42525E;
  color: #42525E;
}

.Language-dropdown-item:hover {
  cursor: pointer;
  background-color: #EDEFF1;
}

.Language-dropdown-item:last-child {
  border-bottom: none;
}

.bordered {
  border: 1px solid #42525E;
}
