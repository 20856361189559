.filter-input-container {
    position: relative;
}

.filter-input {
    border: 1px solid var(--grey);
}

.filter-input-with-content {
    border-color: var(--green);
}

.filter-input-close {
    border: 2px solid var(--dark);
    border-radius: 50%;
    position: absolute;
    right: 7px;
    top: 30px;
    color: var(--dark);
    font-size: 8pt;
    width: 18px;
    height: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}
