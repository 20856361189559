.ProjectNotes-info-block {
    flex: 0 0 382px;
    margin-left: 16px;

    display: flex;
    flex-flow: column;
    background-color: #fff;
    border: 1px solid var(--grey);
    margin-bottom: 16px;
}

.ProjectNotes-info-block h3 {
    margin: 0;
    padding: 8px 16px;
    background-color: var(--green-light);
    border-bottom: 1px solid var(--grey);
    color: var(--green);
}

.ProjectNotes-info-block h3 > span:nth-child(1) {
    margin-right: 16px;
}

.ProjectNotes-content {
    position: relative;
    padding: 16px;
    display: flex;
    flex-flow: column;
    flex: 1;
}

.ProjectNotes-content > * {
    margin-bottom: 16px;
}

.ProjectNotes-content :last-child {
    margin-bottom: 0;
}

.ProjectNotes-fcode {
    position: absolute;
    top: 16px;
    right: 16px;
}

.ProjectNotes-fcode select {
    max-width: 50px;
}

.ProjectNotes-percent-bar {
    height: 24px;
    border-radius: 12px;
    background-color: var(--grey-light);
    font-size: 10pt;
    color: white;
}

.ProjectNotes-percent-bar-content {
    background-color: var(--green);
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ProjectNotes-percent-bar-content-small {
    background-color: initial;
}

.ProjectNotes-percent-bar-label-outside {
    color: var(--green);
    position: relative;
    left: 16px;
}

.ProjectNotes-textarea-container {
    display: flex;
    position: relative;
}

.ProjectNotes-textarea {
    flex: 1;
    border-radius: 5px;
    border: 1px solid var(--grey);
    height: 68px;
    min-height: 68px;
    resize: vertical;
}

.ProjectNotes-textarea-loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ProjectNotes-input-container {
    display: flex;
    position: relative;
}

.ProjectNotes-input-label {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.ProjectNotes-input {
    width: 50px;
    height: 26px;
    font-size: 12pt;
}

.ProjectNotes-input-loader {
    width: 56px;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProjectNotes-administration {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}

.ProjectNotes-administration :nth-child(1) {
    font-weight: bold;
}

.ProjectNotes-documents-title {
    font-weight: bold;
}

.ProjectNotes-documents-control {
    display: flex;
    font-size: 14px;
}

.ProjectNotes-documents-item-name {
    flex: 0 0 100px;
}

.ProjectNotes-documents-item-date {
    flex: 1;
}

.ProjectNotes-pid-row {
    display: flex;
    align-items: center;
}

.ProjectNotes-pid-row:not(:last-child) {
    margin-bottom: 8px;
}

.ProjectNotes-pid-row label {
    flex: 1;
}

.ProjectNotes-pid-row input {
    height: 32px;
    font-size: 12pt;
}

.ProjectNotes-input-checkbox > * {
    cursor: pointer;
}

.ProjectNotes-input-checkbox .toggle_switch {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.ProjectNotes-input-checkbox label {
    flex: unset;
    cursor: pointer;
}

.ProjectNotes-piddates {
    margin-top: 32px;
}
