.tools .tool {
  float: left;
  width: 33%;
  background-color: white;
  border: none;
  border-radius: 10px;
  position: relative;
}
.tools .tool .tool-inner {
  padding: 15px;
}
.tools .tool .tool-inner h2 {
  margin-top: 5px;
}
.tools .tool .tool-inner button {
  width: 100%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2xzLmxlc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsTUFDQztFQUNDLFdBQUE7RUFDQSxVQUFBO0VBQ0EsdUJBQUE7RUFDQSxZQUFBO0VBQ0EsbUJBQUE7RUFDQSxrQkFBQTs7QUFQRixNQUNDLE1BT0M7RUFDQyxhQUFBOztBQVRILE1BQ0MsTUFPQyxZQUVDO0VBQ0MsZUFBQTs7QUFYSixNQUNDLE1BT0MsWUFLQztFQUNDLFdBQUEiLCJzb3VyY2VzQ29udGVudCI6WyIudG9vbHN7XG5cdC50b29se1xuXHRcdGZsb2F0OiBsZWZ0O1xuXHRcdHdpZHRoOiAzMyU7XG5cdFx0YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG5cdFx0Ym9yZGVyOiBub25lO1xuXHRcdGJvcmRlci1yYWRpdXM6IDEwcHg7XG5cdFx0cG9zaXRpb246IHJlbGF0aXZlO1xuXHRcdC50b29sLWlubmVye1xuXHRcdFx0cGFkZGluZzogMTVweDtcblx0XHRcdGgye1xuXHRcdFx0XHRtYXJnaW4tdG9wOiA1cHg7XG5cdFx0XHR9XG5cdFx0XHRidXR0b257XG5cdFx0XHRcdHdpZHRoOiAxMDAlO1xuXHRcdFx0fVxuXHRcdH1cblx0fVxufSJdLCJmaWxlIjoidG9vbHMuY3NzIiwic291cmNlUm9vdCI6Ii9zb3VyY2UvIn0= */
