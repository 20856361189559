.projectWizard-button {
    background-color: var(--green);
}

.ProjectWizard-status-buttons-group button {
    margin-right: 16px;
    margin-bottom: 8px;
}

.form-group .toggle_switch {
  float: unset;
}
