.User-info-container {
    margin-top: 16px;
    display: flex;
    flex-flow: column;
}

.User-info-container > span:nth-child(1) {
    font-weight: bold;
}

.user_contact {
    margin-top: 10px;
    position: static!important;
}

.User-project {
  background-color: white;
  border: 1px solid #b8c4cd;
  margin-top: 40px;
}

.User-project .User-project-row {
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #b8c4cd;
}

.User-project .User-project-row:last-child {
  border-bottom: none;
}

.User-project .User-project-row:not(.User-project-row-title) {
  color: unset;
  text-decoration-line: none;
}

.User-project .User-project-row:hover:not(.User-project-row-title) {
  background-color: #dfe2e6;
}

.User-project .User-project-row .User-project-row-id {
  flex: 1;
}

.User-project .User-project-row .User-project-row-title {
  flex: 6;
}
