@import (inline) "glyphicons.css";
@import "variables.less";

.datepicker__tether-element {
    z-index: 1500 !important;
}

/* Main elements */
html {
    margin: 0 auto;
    background-color: @lightBlue;
    font-family: Helvetica, sans-serif;
    padding: 0;
    color: @darkBlue;
}

body{
    margin: 0;
    padding-top: 135px;
}

a{
    color: @green;
}
a:hover{
    color: darken(@green, 20%);
}
a:active{
    color: #000000;
}

/* Utilities */
.left{
    float: left;
}
.right{
    float: right;
}
.clear{
    clear: both;
}
.fixed_header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 199;


}
.container{
    margin: 0 auto 20px;
    width: 1200px;
}

input[type="search"]::-webkit-search-decoration {
 -webkit-appearance: none;
}

.profile_pic_round{
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    position: absolute;
}
.small.profile_pic_round{
    width: 40px;
    height: 40px;
}
.micro.profile_pic_round{
    width: 30px;
    height: 30px;
}
.big.profile_pic_round{
    width: 130px;
    height: 130px;
}
.profile_pic_round img{
    display: block;
    width: 100%;
    height: 100%;
}

.quickAction {
     color: @darkBlue;
}
.quickAction span.glyphicons{
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.quickAction_tip {
    position: absolute;
    z-index: 1000;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: .85rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    /*opacity: 0;*/
    padding: 5px 0;
    margin-top: 3px;
    right: 18px;
    top: 20px;
}
.quickAction_tip_arrow{
    border-color: transparent;
    border-style: solid;
    top: 0;
    right: 0;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: @darkBlue;
    position: absolute;
    width: 0;
    height: 0;
    
}
.quickAction_tip_inner{
    max-width: 500px;
    min-width: 250px;
    padding: 8px 20px;
    color: @darkBlue;
    text-align: left;
    background-color: #FFFFFF;
    border: 1px solid @darkBlue;
}
.quickAction_tip_inner ul{
    list-style: none;
    margin: 0;
}
.quickAction_tip_inner ul a{
    text-decoration: none;
    font-weight: bold;
    color: @darkBlue;
    display: block;
    &:hover{
        color: lighten(@darkBlue, 25%)
    }
}

ul.dd_list{
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    border: 1px solid @darkBlue;
    list-style: none;
    z-index: 100;
    li {
        color: @darkBlue;
        padding: 8px 20px;
        border-bottom: 1px solid @darkBlue;
        &:hover{
            background-color: @lightBlue;
        }
        &:last-child{
            border-bottom: none;
        }
    }
}
ul.dd_list .dd_menuList_title{
    background-color: #E4E4E4;
}
ul.dd_list .dd_menuList_action{
    background-color: #E43A3A
}
ul.dd_list .dd_menuList_action a, ul.dd_list .dd_menuList_action .glyphicons{
    color: #ffffff;
}

ul.dd_list li a{
    color: @darkBlue;
    text-decoration: none;
    vertical-align: bottom;
    font-size: 18px;
}
ul.dd_list li a .glyphicons{
    color: @darkBlue;
}

.amount{
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    background-color: #199246;
}
.label{
    display: inline-block;
    padding: .25em .4em;
    margin: 0 7px;
    font-size: 75%;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}
.label.blue{
    background-color: #00b9ee;
}
.label.purple{
    background-color: #9185be;
}
.label.red{
    background-color: #ff002a;
}
.label.orange{
    background-color: @orange;
}
.label.green{
    background-color: #199246;
}
.label.darkred{
    background-color: #b33300;
}
.label.services{
    background-color: #E2E2E2;
    color: #A7A7A7;
    text-shadow: 1px 1px #fff;
    box-shadow: 1px 1px #CCC;
}
.glyphicons.red{
    color: #ff002a;
}
.glyphicons.orange{
    color: @orange;
}

.text_right{
    text-align: right;
}

.toggle_switch{
    display: inline-block;
    float: right;
    position: relative;
    top:7px;
    .toggle_fake_label{
        position: absolute;
        right: 80px;
        display: block;
        width: 300px;
        text-align: right;
        padding-top: 10px;
    }
    input.toggle {
        position: absolute;
        margin-left: -9999px;
        visibility: hidden;
    }
    input.toggle + label{
        display: block;
        position: relative;
        cursor: pointer;
        outline: none;
        user-select: none;
        padding: 2px;
        width: 60px;
        height: 30px;
        background-color: darken(@lightBlue, 10%);
        border-radius: 30px;
        transition: background 0.4s;
        &:after, &:before{
            display: block;
            position: absolute;
            content: "";
        }
        &:before{
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
            background-color: #fff;
            border-radius: 30px;
            transition: background 0.4s;
        }
        &:after{
            top: 4px;
            left: 4px;
            bottom: 4px;
            width: 28px;
            background-color: darken(@lightBlue, 10%);
            border-radius: 22px;
            transition: margin 0.4s, background 0.4s;
        }
    }
    input.toggle:checked + label{
        background-color: #199246;
    }

    input.toggle:checked + label:after{
        margin-left: 24px;
        background-color: #199246;
    }
}
    

fieldset{
    min-width: 0;
    margin: 0;
    padding: 0;
    border:0;
}
.form-group {
    margin-bottom: 15px;
    position: relative;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
input{
    margin: 0;
    outline-color: #199246;
}
.form-control {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: .0625rem solid #ccc;
    border-radius: .25rem;
    box-sizing: border-box;
}
select.form-control{
    height: 38px;
}
.form-control:focus + .form-info{
    display: block !important; 
}

.form-submit{
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246
}
.form-info{
    display: none;
    position: absolute;
    left: 102%;
    top: 26px;
    width: 200px;
    padding: 0 10px;
    border-left: 2px solid #199246;
    font-size: 0.8rem;
    color: #888787;
}

.message{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px; 
    &.warning {
        color: #E43A3A;
    }
    &.success {
        color: @green;
    }
}
.message .message_icon{
    position: absolute;
    font-size: 40px;
    left: -50px;
    top: 0px; 
}
.message a{
    color: #5A5A5A;
}

.pass-strenght{
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: .0625rem solid #ccc;
    border-radius: .25rem;
    box-sizing: border-box;
    margin-bottom: 20px;
    text-align: center;
    overflow: hidden;
    position: relative;
}
.pass-strenght .pass-strenght-color{
    width: 10%;
    height: 30px;
    background-color: #7b7b7b;
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}
.pass-strenght .pass-strenght-percent{
    position: absolute;
    top: 3px;
    left: 45%;
    color: #424242;
}

.btn-back {
    display: inline-block;
    position: relative;
    top: -9px;
    z-index: 1;
    padding: 0 10px 0 30px;
    line-height: 44px;
    text-decoration: none;
    font-size: 12px;
    color: @darkBlue;
    font-weight: 900;
}
.btn-back:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    left: 20px;
    right: 0;
    height: 34px;
    margin-top: 5px;
    z-index: -1;
    border-radius: 5px;
    background: #FFF;
}

.btn-back:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    left: 11px;
    height: 27px;
    width: 26px;
    top: 8px;
    z-index: -2;
    border-radius: 7px 5px 5px 7px;
    background: #FFF;
    
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.btn-normal{
    display: inline-block;
    position: relative;
    top: -9px;
    z-index: 1;
    padding: 0 10px 0 30px;
    line-height: 44px;
    text-decoration: none;
    font-size: 12px;
    color: @darkBlue;
    font-weight: 900;
}
.btn-normal:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    left: 20px;
    right: 0;
    height: 34px;
    margin-top: 5px;
    z-index: -1;
    border-radius: 5px;
    background: #FFF;
}

.required:after { 
    content: "*";
    color: red;
}
.btn-green {
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246;
    padding: 5px 12px;
    display: inline-block;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    &:hover{
        color:#0a3b1c;
    }
    &.btn-low{
        padding: 1px 12px;
    }
    &:disabled {
        background-color: #AEAFAE;
        &:hover {
            cursor: default;
            color: #ffffff;
        }
    }
}
h1 .btn-green{
    margin-left: 15px;

}
.btn-red {
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #ff002a;
    padding: 5px 12px;
    display: inline-block;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    &:hover{
        color:#0a3b1c;
    }
    &.btn-low{
        padding: 1px 12px;
    }
}
.btn-file {
    position: relative;
    overflow: hidden;
    input[type="file"]{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}
.btn-close{
    color: #909090;
    text-decoration: none;
    position: absolute;
    right: 10px;
}

.glyphicons-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Top bar */ 
.top_bar{
    background-color: #ffffff;
    padding: 10px 20px;
    height: 50px;
    .my_planning {
        a {
            font-size: 40px;
            padding: 0 0 0 25px;
            position: relative;
            display: inline-block;
            color: #404040;
            .label {
                position: absolute;
                right: -10px;
                top: -8px;
                background-color: #E4790B;
                font-size: 12px;
            }
            &:hover {
                color: #C2C2C2;
            }
        }
    }
}

.top_bar_brand{
    color: #000000;
    font-weight: bold;
    text-decoration: none;
    font-size: 30px;
    margin: 8px 0 0 0;
    display: inline-block;
    .version{
        display: inline-block;
        color: #199246;
        margin-left: 7px;
        font-weight: 100;
        font-size: 20px;
    }
}
.top_bar_profile{
    color: #000000;
    text-decoration: none;
}
.top_bar_action{
    margin-left: 15px;
    font-size: 30px;
    display: inline-block;
    vertical-align: bottom;
    color: #2B2B2B;
}
.top_bar_search{
    font-size: 30px;
    position: absolute;
    top: 15px;
    left: 50%;
    form{
        width: 300px;
        margin-left: -150px;
        input{
            height: 35px;
            font-size: 22px;
            margin: 0;
            padding: 5px;
            border: 1px solid @darkBlue;
            width: 250px;
            border-radius: 0;
            -webkit-appearance: none;
            outline: none;
            &:focus, &:hover, &:active{
                outline: none;
            }
        }
        button {
            position: relative;
            color: #ffffff;
            background-color: @darkBlue;
            border: 1px solid @darkBlue;
            height: 35px;
            width: 35px;
            font-size: 20px;
            vertical-align: baseline;
            .glyphicons-restart:before {
                -webkit-animation:spin 4s linear infinite;
                -moz-animation:spin 4s linear infinite;
                animation:spin 4s linear infinite;
            }
        }
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.top_bar .profile_pic_round{
    margin-top: 1px;
    margin-left: 10px;
    position: relative;
    top: 3px;
}
.top_bar h1{
    display: inline-block;
    margin: 0 0 0 30px;
    font-size: 19px;
    font-weight: normal;
}

/* Main bar */
.main_bar{
    background-color: #199246;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    h2{
        color: #ffffff;
        margin: 0;
        font-size: 22px;
        display: inline-block;
        vertical-align: top;
        a {
            color: #ffffff;
            text-decoration: none
        }
    }
    > div{
        margin-top: 10px;
        height: 30px;
        a {
            text-decoration: none;
        }
    }
    .glyphicons{
        color: #ffffff;
        font-size: 22px;
        padding: 0 4px;
    }
    .dd_menuList{
        text-decoration: none
    }
    .divider{
        display: inline-block;
        border-right: 1px solid #fff;
        border-left: 1px solid #67C78B;
        margin: 0 10px;
        height: 25px;
    }
    .planning{
        position: relative;
        top: -7px;
        text-decoration: none;
    }
}

/* Content */

.content h3{
    margin-bottom: 5px;
    margin-top: 25px;
}

.warning .list_table tbody, .list_table tbody.warning{
    background-color: #fee0d2;
}
.list_table{
    width: 100%;
    border-collapse: collapse;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    thead {
        th{
            color: lighten(@darkBlue, 15%);
            font-weight: normal;
            text-align: left;
            font-size: 13px;
            font-style: italic;
            padding: 0 0 0 10px;
            height: 40px;
            &.sortables{
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    tbody{
        background-color: #ffffff;
        tr{
            border: 1px solid lighten(@darkBlue, 45%);
            cursor: pointer;
            td {
                padding: 13px;
                position: relative;
                &.btnAction_cel {
                    text-align: right;
                    width: 110px;
                    padding: 0 13px;
                    .btn-green{
                        margin-right: 10px;
                    }
                }
                .label:first-of-type{
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            &.no-hover{
                cursor: initial;
                &:hover{
                    background-color: #ffffff;
                }
            }
            &.no-pointer{
                cursor: initial;
            }
            &.reveal-droppable {
                &.ok{
                    background-color: lighten(#199246, 20%);
                }
                &.notok{
                    background-color: lighten(#E4790B, 20%);
                }
            }
            &.planning_date{
                font-weight: 900;
                text-align: left;
                font-size: 17px;
                text-transform: uppercase;
                background-color: #199246;
                color: white;
                border-color: #199246;
                border-bottom-color: #00712A;
                border-bottom-width: 2px;
                td{
                    padding: 7px 25px;
                }
                &:hover{
                 background-color: #00712A;
                }
            }
            &:hover{
                background-color: darken(@lightBlue, 5%);
            }
            &.dropOnMe{
                background-color: #00712A;
            }
            &.quickAction_cel{
                width: 5px;
                padding: 0px;
                font-size: 26px;
                color: #C7C7C7;
            }
        }
    }
}
.content {
    .top_tab{
        margin-top: -40px;
        display: flex;
        position: fixed;
        width: 1200px;
        z-index: 199;
        button {
            border: none;
            background-color: #fff;
            padding: 10px 30px;
            font-size: 17px;
            font-variant: small-caps;
            font-weight: 900;
            color: darken(@darkBlue, 10%);
            cursor: pointer;
            outline: none;
            margin: 0;
            flex: 1;
            border-bottom: 3px solid #aaa0;
            &.active {
                background-color: @green;
                color: #fff;
                border-bottom: 3px solid #fff;
                .amount {
                    background-color: #fff;
                    color: @green;
                }
            }
            &:hover{
                border-bottom: 3px solid @green;
            }
            .amount {
                font-variant: initial;
                font-weight: initial;
            }
        }
    }
}

.list_table tbody tr td .profile_pic_round{
    top: 7px;
    z-index: 21;
}
.list_table tbody tr td .profile_pic_round.active{
    z-index: 25;
}
.list_table tbody tr td  .profile_pic_name{
    position: absolute;
    background-color: #EAEAEA;
    padding:  5px 20px 0 40px;
    border-radius: 30px;
    height: 23px;    
    top: 7px;
    color: #A5A5A5;
    border: solid 1px #BFBFBF;
    z-index: 24;
    white-space: nowrap;
}

/* Form */
.container.form{
    width: 600px;
    .radio{
        label{
            width: 100%;
            display: block;
            padding: 15px 10px;
            cursor: pointer;
            border-radius: 5px;
            &:hover{
                background-color: white;
            }
            input{
                margin-right: 10px;
            }
            .insert{
                padding: .375rem .75rem;
                font-size: 1rem;
                line-height: 1.5;
                color: #55595c;
                background-color: #fff;
                background-image: none;
                border: .0625rem solid #ccc;
                border-radius: .25rem;
                box-sizing: border-box;
            }
        }
    }
}

/* Info block */
.info_block{
    background-color: #fff;
    border: 1px solid darken(@lightBlue, 10%);
    padding: 10px;
    position: relative;
    margin-bottom: 20px;
    header{
        margin-bottom: 10px;
        h3{
            margin: 5px 0 10px;
        }
        div{
            font-style: italic;
        }
    }
    .column_50p{
        width: 45%;
        float: left;
        margin: 2.5%;
    }
    .vc {
        float: right;
        border-left: 1px solid #bfbfbf;
        width: 230px;
        padding-left: 10px;
        text-align: center;
        h5{
            margin: 0 0 10px 0;
            font-weight: lighter;
            font-size: 17px;
        }
        .vc_profile{
            margin-bottom: 20px;
            &.small{
               min-height: 45px;
            }
            .profile_pic_round{
                position: relative;
            }
            h6{
                margin: 5px 0 0 0;
                font-size: 15px;
            }
            a{
                display: block;
                text-decoration: none;
                line-height: 20px;
            }
        }
    }
}

.info_block .edit{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246;
    padding: 5px 12px 0px;
}
.info_block:hover .edit{
    display: block;
}
.touchDevice .info_block .edit{
    display: block;
}



.info_block #map{
    width: 290px;
    height: 230px;
    float: left;
}
.info_block dl{
    margin: 5px 0 0 35px;
    float: left;
}
.info_block dt{
    width: 90px;
    left: 0;
    font-weight: lighter;
}
.info_block dd{
    margin: 0 0 0 90px;
    position: relative;
    top: -1.15em;
}
.info_block .info_block_column1{
    width: 290px;
    float: left;
}
.info_block .info_block_column2 dt{
    width: 110px;
}
.info_block .info_block_column2 dd{
    margin: 0 0 0 110px;
    width: 240px;
}

/* Users */
.users .quickAction_tip{
    right: initial;
    left: -15%;
    top: 30px;
}
.users .quickAction_tip_arrow{
    right: 50%;
}
.users .quickAction_tip_inner{
    min-width: 120px;
}

/* User block */
.user_block{
    background-color: #fff;
    border: 1px solid #bfbfbf;
    padding: 10px;
    position: relative;
    margin-top: 25px;
}
.user_block .edit{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246;
    padding: 5px 12px 0px;
}
.user_block:hover .edit{
    display: block;
}
.touchDevice .user_block .edit{
    display: block;
}
.user_block a{
    text-decoration: none;
}
.user_block .user_profile_pic, .user_block .user_info {
    float: left;
}

.user_block .user_profile_pic{
    padding: 10px 30px 0 20px;
    position: relative;
}
.user_block .user_profile_pic .edit_profile_pic{
    display: none;
    position: absolute;
    bottom: 10px;
    left: 35px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246;
    padding: 5px 12px 5px;
}
.user_block .user_profile_pic:hover .edit_profile_pic{
    display: block;
}
.touchDevice .user_profile_pic .edit_profile_pic{
    display: block;
}
.user_block .user_profile_pic .profile_pic_round{
    position: relative;
    width: 130px;
    height: 130px;
}
.user_block h3{
    margin: 10px 0;
}
.user_block .user_contact{
    top: 10px;
    position: relative;
}
.user_block .user_contact > div{
    margin-bottom: 5px;
}

/* Organisation block */
.organisation_block{
    background-color: #fff;
    border: 1px solid #bfbfbf;
    padding: 10px;
    position: relative;
    margin-top: 25px;
    margin-bottom: 20px;
}
.organisation_block .edit{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246;
    padding: 5px 12px 0px;
}
.organisation_block:hover .edit{
    display: block;
}
.organisation_block a{
    text-decoration: none;
}
.organisation_block .organisation_profile_pic, .organisation_block .organisation_info {
    float: left;
}

.organisation_block .organisation_profile_pic{
    padding: 10px 30px 0 20px;
    position: relative;
}
.organisation_block .organisation_profile_pic .edit_profile_pic{
    display: none;
    position: absolute;
    bottom: 10px;
    left: 35px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: #199246;
    padding: 5px 12px 5px;
}
.organisation_block .organisation_profile_pic:hover .edit_profile_pic{
    display: block;
}
.touchDevice .organisation_block .organisation_profile_pic .edit_profile_pic{
    display: block;
}
.organisation_block .organisation_profile_pic .profile_pic_round{
    position: relative;
    width: 130px;
    height: 130px;
}
.organisation_block h3{
    margin: 10px 0;
}
.organisation_block .organisation_contact{
    top: 10px;
    position: relative;
}
.organisation_block .organisation_contact > div{
    margin-bottom: 5px;
}
.organisation_block dl{
    margin: 5px 0 0 0;
    float: left;
}
.organisation_block dt{
    width: 90px;
    left: 0;
    font-weight: lighter;
}
.organisation_block dd{
    margin: 0 0 0 90px;
    position: relative;
    top: -1.15em;
}

/* double column design*/
.left_menu {
    float: left;
    width: 220px;
    border-right: 1px solid lighten(@darkBlue, 30%);
    ul{
        margin: 0 0 25px 0;
        padding: 0;
        list-style: none;
        li {
            background-color: darken(@lightBlue, 4%);
            margin-bottom: 1px;
            &.active{
                background-color: #fff;
            }
            &:hover{
                background-color: darken(@lightBlue, 8%);
            }
            a {
                color: lighten(@darkBlue, 20%);
                text-decoration: none;
                padding: 6px 0 4px 15px;
                display: inline-block;
                font-weight: 100;
                width: 94%;
                &:hover{
                    color: lighten(@darkBlue, 10%);
                }
            }
        }
    }
}

.right_content{
    float: left;
    width: 717px;
    padding-left: 20px;
}

.ui-draggable-dragging{
    z-index: 1000;
}

/* Planning slider */ 
.slider {
    position: fixed;
    z-index: 200;
    width: 400px;
    height: 100%;
    top: 70px;
    right: -400px;
    background-color: #fff;
    padding-bottom: 70px;
    border-left: 1px solid #D4D4D4;
    &.open{
        right: 0;
        transition: right 0.8s ease-in;
        -ms-transition: right 0.8s ease-in;
        -moz-transition: right 0.8s ease-in;
        -webkit-transition: right 0.8s ease-in;
    }
    &.close{
        right: -400px;
        transition: right 0.4s ease-in;
        -ms-transition: right 0.4s ease-in;
        -moz-transition: right 0.4s ease-in;
        -webkit-transition: right 0.4s ease-in;
    }
    .slider_header{
        height: 30px;
        background-color: #c2c2c2;
        padding: 10px 40px 5px 15px;
        position: relative;
        font-size: 20px;
        .slider_close{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 25px;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
        }
    }
    .slider_content{
        overflow-y: scroll;
        height: 100%;
        .slider_content_inside{
            margin-bottom: 110px;
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        tr{
            border: 1px solid #bfbfbf;
            border-left: none;
            border-top: none;
            th {
                font-weight: 900;
                text-align: left;
                padding: 7px 15px;
                font-size: 17px;
            }
            td {
                padding: 13px;
                padding-left: 25px;
                overflow: hidden;
                position: relative;
            }
        }
    }
}

.planning_table {
    background-color: #fff;
    // margin-top: 70px;
    // padding-bottom: 70px;
    border-left: 1px solid #D4D4D4;
    width: 100%;
    border-collapse: collapse;
    thead{
        tr{
            font-weight: 900;
            text-align: left;
            font-size: 17px;
            text-transform: uppercase;
            background-color: #199246;
            color: white;
            border-color: #199246;
            border-bottom-color: #00712A;
            border-bottom-width: 2px;
        }
    }
    tr {
        border: 1px solid #bfbfbf;
        border-left: none;
        border-top: none;
        td {
            padding: 13px;
            padding-left: 25px;
            overflow: hidden;
            position: relative;
            .controls {
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.85);
                height: 100%;
                width: 100%;
                span {
                    font-size: 30px;
                    margin: 0 77px 0;
                    padding: 5px;
                    cursor: pointer;
                    &.glyphicons-unshare{
                        color: #707070;
                    }
                    &.glyphicons-ok{
                        color: #199246;
                    }
                    &.glyphicons-ban{
                        color: #C2C2C2;
                    }
                    &:hover {
                        -ms-transform: scale(1.2,1.2); /* IE 9 */
                        -webkit-transform: scale(1.2,1.2); /* Safari */
                        transform: scale(1.2,1.2);
                    }
                }
            }
        }
    }
}

.project_dragger{
    padding: 13px;
    border: 2px solid #bfbfbf;
    border-radius: 6px;
    background-color: #fff;
    span.label{
        display: none;
    }
    &.notok{
        span.label.red{
            display: inline;
        }
    }
    &.ok{
        span.label.green{
            display: inline;
        }
    }
}

.dropbox {
    position: fixed;
    width: 960px;
    z-index: 200;
    .ui-droppable {
        position: absolute;
        width: 100%;
        bottom: 0;
        opacity: 0.9;
        > p{
            display: none
        }
        &.dropbox_droppable {
            background-color: #199246;
            border: 2px dashed #ffffff;
            width: 100%;
            height: 50px;
            text-align: center;
            padding-top: 10px;
            animation: animationFrames ease-in-out 0.7s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            animation-fill-mode:forwards; /*when the spec is finished*/
            -webkit-animation: animationFrames ease-in-out 0.7s;
            -webkit-animation-iteration-count: 1;
            -webkit-transform-origin: 50% 50%;
            -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
            -moz-animation: animationFrames ease-in-out 0.7s;
            -moz-animation-iteration-count: 1;
            -moz-transform-origin: 50% 50%;
            -moz-animation-fill-mode:forwards; /*FF 5+*/
            -o-animation: animationFrames ease-in-out 0.7s;
            -o-animation-iteration-count: 1;
            -o-transform-origin: 50% 50%;
            -o-animation-fill-mode:forwards; /*Not implemented yet*/
            -ms-animation: animationFrames ease-in-out 0.7s;
            -ms-animation-iteration-count: 1;
            -ms-transform-origin: 50% 50%;
            -ms-animation-fill-mode:forwards; /*IE 10+*/
            > p{
                display: block;
                color: #ffffff;
            }
        }
    }
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    &.alert-success {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
    }
    &.alert-warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }
    &.alert-danger {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
    }
}

@keyframes animationFrames{
  0% {
    opacity:0;
  }
  100% {
    opacity:0.95;
  }
}

@-moz-keyframes animationFrames{
  0% {
    opacity:0;
  }
  100% {
    opacity:0.95;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    opacity:0;
  }
  100% {
    opacity:0.95;
  }
}

@-o-keyframes animationFrames {
  0% {
    opacity:0;
  }
  100% {
    opacity:0.95;
  }
}

@-ms-keyframes animationFrames {
  0% {
    opacity:0;
  }
  100% {
    opacity:0.95;
  }
}


.iti-flag {
    background-image: url("/images/flags.png");
}

@import "logs.less";
@import "tools.less";

.filter-container {
    background-color: white;
    border: 1px solid #bbc4cd;
    padding: 5px;
    color: #bbc4cd;
    position: fixed;
    width: 208px;
    margin-top: 40px;
    z-index: 1;
}
.filter-input-container {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    input {
        min-height: 30px;
        font-size: 14pt;
    }
}
.filter-title-container {
    margin-bottom: 15px;
}
.filter-title-container > .amount {
    margin-left: 10px;
}
.filter-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
}
.filter-label {
    font-size: 13px;
}
.filter-input-container > input {
    min-height: 30px;
}
.toggle_switch > .filter-label {
    margin-bottom: 8px;
    cursor: default;
}
.filter-button {
    border-radius: 5px;
    background-color: #bbc4cd;
    padding: 10px;
    border: 0;
    color: white;
    font-size: 16px;
    width: 100%;
}

.projects-container, .planning-container {
    margin-top: 20px;
}
.projects_table, .planning-table {
    margin-left: 240px;
}
.planning-table .list_block {
    padding-top: 40px;
}
.planning-table.my-planning {
    padding-top: 40px;
    margin-left: 0;
}
.planning-table.planned-table {
    padding-top: 40px;
}

.table-loader {
    text-align: center;
}

.planning-table-title {
    font-size: 15px;
}

.form-group-date-picker {
    display: flex;
    .react-datepicker__input-container {
        width: 100%;
    }
}

.mute-page-date-picker {
    display: inline-block;
    width: 400px;
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker__input-container {
        width: 100%;
    }
}
