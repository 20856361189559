@import "variables.less";

ul.logs{
	list-style: none;
	border-left: 4px solid @darkBlue;
	margin-left: 60px;
	padding: 0 0 20px 0;
	> li {
		left: -40px;
    	position: relative;
    	margin-top: 30px;
    	.logs_date{
    		width: 80px;
    		text-align: center;
    		background: @green;
    		color: white;
    		padding: 5px 0;
    		border-radius: .25rem;
    		text-transform: uppercase;
		    font-weight: 900;
		    font-size: 18px;
    	}
    	ul {
    		list-style: none;
    		padding: 10px 0 0 0;
    		margin-left: 10px;
    		li{
    			background: white;
    			border: 1px solid lighten(@darkBlue, 25%);
    			border-radius: .25rem;
    			margin-bottom: 10px;
    			position: relative;
    			.logs_time{
    				background: @green;
    				color: white;
    				width: 57px;
				    position: absolute;
				    height: 100%;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    font-weight: 100;
				    font-size: 14px;
    			}
    			.logs_log{
    				padding: 20px 20px 20px 70px;
    			}
    		}
    	}
	}
}