.ProjectDesign-info-block {
    background-color: #fff;
    border: 1px solid var(--grey);
    margin-bottom: 16px;
}

.ProjectDesign-info-block h3 {
    margin: 0;
    padding: 8px 16px;
    background-color: var(--green-light);
    border-bottom: 1px solid var(--grey);
    color: var(--green);
}

.ProjectDesign-info-block h3 > span:nth-child(1) {
    margin-right: 16px;
}

.ProjectDesign-content {
    margin: 16px;
}

.ProjectDesign-content .ProjectDesign-file-row:nth-child(1) .ProjectDesign-control {
    margin-top: 0;
}

.ProjectDesign-tick {
    width: 16px;
    margin-right: 8px;
    padding-top: 8px;
    color: var(--green);
}

.ProjectDesign-tick > span {
    display: none;
}

.ProjectDesign-tick-active > span {
    display: initial;
}

.ProjectDesign-file-row {
    display: flex;
}

.ProjectDesign-file-input {
    display: none;
}

.ProjectDesign-control {
    flex: 1;
    min-height: 64px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-top: 8px;
}

.ProjectDesign-name {
    font-weight: bold;
    font-size: 10pt;
}

.ProjectDesign-file-container {
    padding: 0 16px;
    border: 1px solid var(--grey);
    min-height: 40px;
    display: flex;
    align-items: center;
    position: relative;
}

.ProjectDesign-file-name {
    flex: 0 0 480px;
}

.ProjectDesign-file-date {
    flex: 0 0 192px;
}

.ProjectDesign-file-user {
    flex: 0 0 208px;
}

.ProjectDesign-file-download {
    flex: 0 0 168px;
}

.ProjectDesign-file-menu {
    flex: 1;
    position: relative;
}

.ProjectDesign-input-message {
    flex: 1;
    font-size: 10pt;
}

.ProjectDesign-input-button {
    color: white;
    background-color: var(--green);
    border: none;
    height: 24px;
    font-weight: bold;
    font-size: 10pt;
    border-radius: 5px;
    cursor: pointer;
}

.ProjectDesign-input-button:hover {
    color: var(--green-dark);
}

.ProjectDesign-input-button span:nth-child(1) {
    margin-right: 8px;
}

.ProjectDesign-submit-container {
    display: flex;
    justify-content: flex-end;
}

.ProjectDesign-submit {
    height: 32px;
    border-radius: 5px;
    border: none;
    background-color: var(--grey-dark);
    color: white;
    font-weight: bold;
    font-size: 12pt;
    width: 336px;
    margin-top: 16px;
    cursor: pointer;
}

.ProjectDesign-submit:hover {
    color: var(--very-dark);
}

.ProjectDesign-submit.active {
    background-color: var(--green);
}

.ProjectDesign-upload-container {
    position: relative;
    width: 100%;
    padding-bottom: 8px;
}

.ProjectDesign-close-button {
    border: none;
    font-size: 12pt;
    font-family: Helvetica, sans-serif;
    right: 0;
    top: 8px;
    padding-right: 0;
    cursor: pointer;
}

.ProjectDesign-close-button:hover {
    color: var(--green-dark);
}

.ProjectDesign-applicable-checkbox {
    display: inline;
    margin-left: 16px;
    font-weight: normal;
}

.ProjectDesign-file-disabled {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ffffff94;
    z-index: 1;
}
