.Project-info-cd-menu {
    margin-bottom: 0!important;
    background-color: #edeff1;
    padding-left: 32px!important;
}

.Project-info-cd-menu-button {
    border-top: 1px solid #edeff1;
    position: relative;
}

.Project-info-cd-menu-button a {
    cursor: pointer!important;
}

.Project-info-cd:hover {
    background-color: #e2e5e8!important;
}

.Project-info-cd a {
    cursor: default;
}

.Project-info-cd-menu-indent {
    position: absolute;
    left: -32px;
    height: 28px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Project-info-cd-menu-indent-lines {
    height: 10px;
    width: 10px;
    border: 1px solid var(--grey-dark);
    border-top: none;
    border-right: none;
}

.SecondaryInfo {
    display: flex;
}

.SecondaryInfo .left_menu,.SecondaryInfo .right_content {
    float: unset;
}

.Project-info-cd-fact-toggle {
    display: flex;
    flex-flow: column;
    align-items: center;
    grid-gap: 4px;
}
