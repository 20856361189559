.ProjectPlanning-info-block {
    background-color: #fff;
    border: 1px solid var(--grey);
    margin-bottom: 16px;
}

.ProjectPlanning-info-block h3 {
    margin: 0;
    padding: 8px 16px;
    background-color: var(--green-light);
    border-bottom: 1px solid var(--grey);
    color: var(--green);
}

.ProjectPlanning-info-block h3 > span:nth-child(1) {
    margin-right: 16px;
}

.ProjectPlanning-content {
    display: flex;
    padding: 16px;
}

.ProjectPlanning-buttons {
    flex: 0 0 296px;
}

.ProjectPlanning-buttons > a, .ProjectPlanning-buttons > button {
    margin-top: 16px;
    width: 100%;
}

.ProjectPlanning-buttons > a {
    box-sizing: border-box;
    align-items: flex-start;
    text-align: center;
}

.ProjectPlanning-buttons > *:nth-child(1) {
    margin-top: 0;
}

.ProjectPlanning-dates {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 24px;
}

.ProjectPlanning-date {
    display: flex;
    flex-flow: column;
    margin-bottom: 8px;
}

.ProjectPlanning-date span:nth-child(1) {
    font-weight: bold;
}

.ProjectPlanning-title-reason {
    background-color: #fdffe4;
    border-radius: 4px;
    padding: 2px 4px;
    margin-left: 8px;
    color: #42525E;
    font-size: 12pt;
}

.OnHold-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000099;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}
.OnHold-modal-hidden {
    display: none;
}


.OnHold-modal-body {
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-flow: column;
    grid-gap: 8px;
}

.OnHold-modal-title {
    margin: 0;
    margin-bottom: 16px;
}

.OnHold-modal-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 8pt;
    font-weight: bold;
    padding: 0;
    background-color: transparent;
    color: #4a4a4af0;
    border: 2px solid var(--dark);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.OnHold-modal-icon:hover {
    background-color: #0000000f;
}
