.vgpFileView-container {

}

.vgpFileView-row {
    height: 22px;
    padding: 8px;
    margin-bottom: 8px;
    background-color: white;
    border: 1px solid var(--grey);
    display: flex;
}

.vgpFileView-row-secret {
    background-color: var(--green-light);
}

.vgpFileView-row-indent {
    margin-left: 40px;
    position: relative;
}

.vgpFileView-row > *:nth-child(1) {
    flex: 1;
    margin-left: 8px;
}

.vgpFileView-row > *:nth-child(2) {
    flex: 0 0 176px;
}

.vgpFileView-row > *:nth-child(3) {
    margin-left: 8px;
    flex: 0 0 192px;
}

.vgpFileView-button {
    height: 24px;
    border: none;
    border-radius: 5px;
    background-color: var(--green);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 10pt;
    cursor: pointer;
}

.vgpFileView-button:hover {
    color: var(--green-dark);
}

.vgpFileView-button .glyphicons {
    font-size: 12pt;
}

.vgpFileView-text {
    display: flex;
    align-items: center;
}

.vgpFileView-row-secret .vgpFileView-text {
    color: var(--green);
}

.vgpFileView-text-icon {
    margin-right: 8px;
}

.vgpFileView-indent {
    position: absolute;
    left: -40px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
}

.vgpFileView-indent-lines {
    height: 20px;
    width: 20px;
    border: 1px solid var(--grey-dark);
    border-top: none;
    border-right: none;
}

.vgpFileView-link {
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.vgpFileView-link > a > span:nth-child(1) {
    text-decoration: none;
}

.vgpFileView-link > a > span:nth-child(1) {
    margin-right: 8px;
}

.vgpFileView-link > a > span:nth-child(2) {
    text-decoration: underline;
}
