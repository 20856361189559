.ProjectDetails-space {
    display: flex;
    justify-content: space-between;
}

.ProjectDetails-info-block {
    flex: 1;
    background-color: #fff;
    border: 1px solid var(--grey);
    position: relative;
    margin-bottom: 16px;
}

.ProjectDetails-info-block h3 {
    margin: 0;
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 16px;
    border-bottom: 1px solid var(--grey);
}

.ProjectDetails-info-block-label {
    flex: 1;
}

.ProjectDetails-tags {
    display: flex;
    max-width: 169px;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.ProjectDetails-body {
    display: flex;
    padding: 16px;
}

.ProjectDetails-left {
    flex: 0 0 304px;
    border: 1px solid var(--grey);
    border-radius: 5px;
    height: 100%;
}

.ProjectDetails-right {
    flex: 1;
    padding-left: 24px;
    flex-flow: column;
}

.ProjectDetails-map {
    width: 304px;
    height: 255px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.ProjectDetails-address {
    display: flex;
    flex-flow: column;
    padding: 10px;
}

.ProjectDetails-info {
    display: flex;
    flex-flow: column;
}

.ProjectDetails-info > div {
    display: flex;
}

.ProjectDetails-info > div > div {
    flex: 1;
    display: flex;
    flex-flow: column;
}

.ProjectDetails-info-header {
    margin-bottom: 8px;
    font-weight: bold;
}

.ProjectDetails-info-text {
    margin-bottom: 12px;
}

.ProjectDetails-vc {
    border-top: 1px solid var(--grey);
}

.ProjectDetails-vc-header {
    margin: 16px 0;
    font-size: 10pt;
    display: flex;
    justify-content: space-between;
}

.ProjectDetails-vc-details-container {
    display: flex;
}

.ProjectDetails-vc-details-image {
    flex: 0 0 160px;
}

.ProjectDetails-vc-details-image > img {
    border-radius: 50%;
    width: 128px;
    height: 128px;
}

.ProjectDetails-vc-details {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.ProjectDetails-vc-details span:nth-child(1) {
    font-weight: bold;
    margin-bottom: 16px;
}

.ProjectDetails-info-block .edit {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #ffffff;
    border-radius: .25rem;
    border: 0;
    background-color: var(--green);
    padding: 5px 12px 0px;
}

.ProjectDetails-info-block:hover .edit {
    display: initial;
}

.ProjectDetails-mail-buttons {
    display: flex;
    flex-flow: column;
    padding: 0;
    width: 140px;
    min-width: unset;
}

.ProjectDetails-mail-buttons button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 24px;
    font-family: Helvetica, sans-serif;
    color: #42525E;
    font-weight: bold;
    text-align: left;
    padding-left: 20px;
}

.ProjectDetails-mail-buttons button:hover {
    background-color: #00000010;
}
